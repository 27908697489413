import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./article-details.module.scss";
import DetailDate from "./detail-date/detail-date";
import DetailArticleType from "./detail-article-type/detail-article-type";
import DetailAuthor from "./detail-author/detail-author";

ArticleDetails.propTypes = {
    authorName: PropTypes.string,
    authorUrl: PropTypes.string,
    date: PropTypes.string,
    articleType: PropTypes.string,
};


export default function ArticleDetails({authorName, authorUrl, date, articleType, displayType = "" }) {
    let topLevelClassName = '';
    if (displayType) {
        topLevelClassName = styles[displayType];
    }
    else {
        topLevelClassName = styles.details
    }
    return (
        <div className={`${topLevelClassName} details`}>
            {date ? <DetailDate date={date}></DetailDate> : ''}
            {articleType ? <DetailArticleType articleType={articleType}></DetailArticleType> : ''}
            {authorName ? <DetailAuthor authorUrl={authorUrl} authorName={authorName}></DetailAuthor> : ''}
        </div>
    )
}