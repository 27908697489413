import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./detail-article-type.module.scss";

DetailArticleType.propTypes = {
    articleType: PropTypes.string,
};

export default function DetailArticleType({ articleType}) {
    return (
        <span className={styles.articleType}>{articleType}</span>
    )
}