import React from "react";
import PropTypes from 'prop-types';
import { GatsbyImage,getImage } from "gatsby-plugin-image"
import * as styles from "./homepage-library-section.module.scss";
import {Link} from 'gatsby-plugin-react-i18next';

HomepageLibrarySection.propTypes = {
    title: PropTypes.string,
    imageObj: PropTypes.object,
    link: PropTypes.string,
};

export default function HomepageLibrarySection({title, imageObj,  link, imageAlt}) {
    return (
        <div className={`${styles.card} lib-section`}>
            <Link to={link}>
                <GatsbyImage image={imageObj} alt={imageAlt} />
                <div className={styles.title}>{title}</div>
            </Link>
        </div>
    )
}