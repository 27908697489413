import React from "react"
import Global from "../global/global"
import * as styles from "./homepage.module.scss";
import ColumnHeader from "../../components/column-header/column-header";
import HomepageGiving from "../../components/blocks/homepage-giving/homepage-giving";
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next';
import HomepageTagline from "../../components/blocks/homepage-tagline/homepage-tagline";

export default function Homepage({children, latestArticles, latestWireArticles, latestPodcasts, library, heroSlides, homepageData }) {
    const { t } = useTranslation();

    let tagline = '';
    if (
      homepageData.hasOwnProperty('field_homepage_tagline') &&
      homepageData.field_homepage_tagline !== null &&
      homepageData.field_homepage_tagline.hasOwnProperty('processed') &&
      homepageData.field_homepage_tagline.processed !== null &&
      homepageData.field_homepage_tagline.processed.length >= 2
      ) {
      tagline = <HomepageTagline
        text={homepageData.field_homepage_tagline.processed}
        size={homepageData.field_homepage_tagline_size}>
      </HomepageTagline>;
    }

    return (
        <Global>
            {children}
            <main className={styles.main}>
                {tagline}
                <section className={`${styles.section} ${styles.top}`}>
                    <section className={styles.latest}>
                        {latestArticles}
                    </section>
                    <section className={styles.hero}>
                        need new carousel option for react/node 18
                    </section>
                </section>

                <section className={`${styles.section} ${styles.middle}`}>
                    <section className={styles.currentArticles}>
                        {latestWireArticles}
                    </section>
                    <section className={`${styles.podcasts}`}>
                        <ColumnHeader
                            title={t('homepage.section.podcasts', 'Podcasts')}
                            iconType={"podcast"}
                            url='podcasts.xml'>
                        </ColumnHeader>
                        {latestPodcasts}
                    </section>
                    <section className={styles.library}>
                        <ColumnHeader
                            title={t('homepage.section.library', 'Library')}
                            iconType={"rss"}
                            url='library.xml'>
                        </ColumnHeader>
                        {library}
                    </section>
                </section>

                <section className={`${styles.section} ${styles.bottom}`}>
                    <section className={styles.powerMarket}>
                        <ColumnHeader
                            title={t('homepage.section.power_market', 'Power & Market')}>
                        </ColumnHeader>


                        power and market items
                    </section>
                    <section className={styles.events}>
                        <ColumnHeader
                            title={t('homepage.section.events', 'Events')}
                        >
                        </ColumnHeader>
                        events list

                    </section>
                    <section className={styles.supportMises}>
                        <HomepageGiving
                            title={homepageData.field_homepage_giving_title}
                            videoLink={homepageData.field_giving_video_embed_url}
                            givinglink={homepageData.field_giving_link.uri}
                            buttonText={homepageData.field_giving_link.title}
                            givingText={homepageData.field_homepage_giving_body.processed}
                        >
                        </HomepageGiving>
                    </section>
                </section>
            </main>
        </Global>
    )
}
