import React from "react";
import PropTypes from "prop-types";
import slugify from "slugify";
import { Link } from "gatsby-plugin-react-i18next";

import * as styles from "./detail-article-tag-link.module.scss";


DetailArticleTagLink.propTypes = {
  key: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
};

export default function DetailArticleTagLink({ key, id, name }) {

  const encodedName = slugify(name, {
    lower: true,
    strict: true,
  });
  const url = "/" + encodedName;

  return (
    <Link to={url} key={key ? key : id} className={styles.tag}>{name}</Link>
  )
}
