import React from "react";
import PropTypes from 'prop-types';
import { GatsbyImage,getImage } from "gatsby-plugin-image"
import ArticleDetails from "../article-details/article-details";
import * as styles from "./homepage-podcasts.module.scss";
import * as articleDetailStyles from "../article-details/article-details.module.scss"
import {Link} from 'gatsby-plugin-react-i18next';

HomepagePodcasts.propTypes = {
    title: PropTypes.string,
    teaser: PropTypes.string,
    imageObj: PropTypes.object,
    articleUrl: PropTypes.string,
    authorName: PropTypes.string,
    authorUrl: PropTypes.string,
    date: PropTypes.string,
    articleType: PropTypes.string,
};

export default function HomepagePodcasts({title, imageObj, teaser, articleUrl, authorName, authorUrl, date, articleType, cssClassName }) {
    return (
        <article className={`${styles.card} card`}>
            <section className={styles.media}>
                <Link to={articleUrl}>
                    <GatsbyImage image={imageObj} alt={title} />
                </Link>
            </section>
            <section className={styles.text}>
                <h2 className={styles.title}><Link to={articleUrl}>{title}</Link></h2>
                <ArticleDetails
                    displayType="homepagePodcast"
                    articleType={articleType}
                    authorUrl={authorUrl}
                    date={date}
                    authorName={authorName}>
                </ArticleDetails>
            </section>
        </article>
    )
}