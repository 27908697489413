import React from "react";
import {Helmet} from "react-helmet";

export default function Metatags({metatagData}) {

    if (metatagData === undefined) {
        return ''
    }

    const metatags = metatagData.map((metatag,index) => {

        if (metatag.attributes.name != null && metatag.attributes.name == 'title') {
            return <title key={index}>{metatag.attributes.content}</title>
        }
        else {
            let properties = {};
            for (const property in metatag.attributes) {
                if (metatag.attributes[property] != null) {
                    properties[property] = metatag.attributes[property];
                }
            }
            properties['key'] = index;
            let tagContent = React.createElement(metatag.tag, properties);
            return tagContent
        }

    });

    return (
        <Helmet>
            {metatags}
        </Helmet>
    )
}
