import React from "react"
import * as styles from "./column-header.module.scss";
import iconItunes from "../../images/icons/icon-itunes.svg"
import iconRss from "../../images/icons/icon-rss.svg"

export default function ColumnHeader({ title, iconType = '', url = '' }) {

    let imagePath = null;
    if (iconType === "podcast") {
        imagePath = iconItunes;
    }
    if (iconType === "rss") {
        imagePath = iconRss;
    }


    let iconClassName = '';
    if (iconType) {
        iconClassName = styles[iconType];
    }


    let link = ''
    if (url !== '') {
        link = <a className={`${iconClassName} icon`} href={url}><img src={imagePath} /></a>;
    }

    return (
        <div className={styles.columnHeader}>
            <h2 className={styles.title}>{title}</h2>
            {link}
        </div>
    )
}