import React, { useRef } from "react"
import useIntersectionObserver from '@react-hook/intersection-observer'
import * as styles from "./lazy-iframe.module.scss";

const LazyIframe = ({ url, title }) => {
  const containerRef = useRef()
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)
  if (isIntersecting && !lockRef.current) {
      lockRef.current = true
  }
  return (
      <div ref={containerRef} className={styles.videoWrapper}>
          {lockRef.current && (
              <iframe
                  title={title}
                  src={url}
                  loading="lazy"
                  className={styles.iframe}
              ></iframe>
          )}
      </div>
  )
}

export default LazyIframe
