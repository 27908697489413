import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./detail-date.module.scss";

DetailDate.propTypes = {
    date: PropTypes.string,
};

export default function DetailDate({ date}) {
    return (
        <span className={styles.publishDate}>{date}</span>
    )
}