import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./detail-author.module.scss";
import {Link} from 'gatsby-plugin-react-i18next';

DetailAuthor.propTypes = {
    authorName: PropTypes.string,
    authorUrl: PropTypes.string,
};

export default function DetailAuthor({ authorName, authorUrl}) {
    return (
        <span className={styles.author}><Link to={authorUrl}>{authorName}</Link></span>
    )
}