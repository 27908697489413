import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./homepage-tagline.module.scss";

HomepageTagline.propTypes = {
    title: PropTypes.string,
    size: PropTypes.string,
};

export default function HomepageTagline({text, size}) {
    // https://tailwindcss.com/docs/font-size
    let sizeRem = '1rem'
    if (size === "text-xs") {
      sizeRem = '0.75rem'
    }
    if (size === "text-sm") {
      sizeRem = '0.875rem'
    }
    if (size === "text-base") {
      sizeRem = '1rem'
    }
    if (size === "text-lg") {
      sizeRem = '1.125rem'
    }
    if (size === "text-xl") {
      sizeRem = '1.25rem'
    }
    if (size === "text-2xl") {
      sizeRem = '1.5rem'
    }
    if (size === "text-3xl") {
      sizeRem = '1.875rem'
    }
    if (size === "text-4xl") {
      sizeRem = '2.25rem'
    }
    if (size === "text-5xl") {
      sizeRem = '3rem'
    }
    if (size === "text-6xl") {
      sizeRem = '3.75rem'
    }
    if (size === "text-7xl") {
      sizeRem = '4.5rem'
    }
    if (size === "text-8xl") {
      sizeRem = '6rem'
    }
    if (size === "text-9xl") {
      sizeRem = '8rem'
    }

    if (text !== null || text.length >= 2) {
      return (
        <p style={{fontSize: sizeRem}} className={styles.tagline}>{text}</p>
      )
    }

}
