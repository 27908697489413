import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./homepage-giving.module.scss";
import LazyIframe from "../lazy-iframe/lazy-iframe";

HomepageGiving.propTypes = {
    title: PropTypes.string,
    imageObj: PropTypes.object,
    link: PropTypes.string,
};

export default function HomepageGiving({title, videoLink,  givinglink, buttonText, givingText}) {
    return (
        <div className={styles.giving}>
            <LazyIframe url={videoLink} title={title}></LazyIframe>
            <div className={styles.textWrapper}>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.text}>
                    {givingText}
                </div>
                <a href={givinglink} className={styles.button}>{buttonText}</a>
            </div>

        </div>
    )
}
