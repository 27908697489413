import React from "react";
import PropTypes from 'prop-types';
import { GatsbyImage,getImage } from "gatsby-plugin-image"
import ArticleDetails from "../article-details/article-details";
import * as styles from "./article-image-title-teaser.module.scss";
import {Link} from 'gatsby-plugin-react-i18next';

ArticleImageTitleTeaser.propTypes = {
    title: PropTypes.string,
    teaser: PropTypes.string,
    imageObj: PropTypes.object,
    articleUrl: PropTypes.string,
    authorName: PropTypes.string,
    authorUrl: PropTypes.string,
    date: PropTypes.string,
    articleType: PropTypes.string,
};

export default function ArticleImageTitleTeaser({title, imageObj, teaser, articleUrl, authorName, authorUrl, date, articleType, langCode }) {
    return (
        <article className={`${styles.card}`}>
            <section className={styles.media}>
                <Link to={articleUrl}>
                    <div className={styles.image}>
                        <GatsbyImage image={imageObj} alt={title} />
                    </div>

                </Link>
            </section>
            <section className={styles.text}>
                <h2 className={styles.title}><Link to={articleUrl}>{title}</Link></h2>
                <ArticleDetails
                    articleType={articleType}
                    authorUrl={authorUrl}
                    date={date}
                    authorName={authorName}>
                </ArticleDetails>
                <div className={styles.teaser} dangerouslySetInnerHTML={{ __html: teaser }}></div>
            </section>
        </article>
    )
}