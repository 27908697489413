import React from "react";
import * as styles from "./article-title-teaser.module.scss";
import {Link} from 'gatsby-plugin-react-i18next';
import ArticleDetails from "../article-details/article-details";
import DetailArticleTagLink from "../article-details/detail-article-tag-link/detail-article-tag-link"

export default function ArticleTitleTeaser({
  title,
  image,
  teaser,
  articleUrl,
  authorName,
  authorUrl,
  date,
  articleType,
  tags,
}) {

  return (
    <article className={`${styles.card} card`}>
      <section className={styles.text}>
        {tags.length >= 1 && (
          <div className={styles.tags}>
            {tags.map((tag, index, arr) => (
              <DetailArticleTagLink
                key={tag.id}
                id={tag.id}
                name={tag.name}
              />
            ))}
          </div>
        )}
        <h2 className={styles.title}>
          <Link to={articleUrl}>{title}</Link>
        </h2>
        <ArticleDetails
          articleType={articleType}
          authorUrl={authorUrl}
          date={date}
          authorName={authorName}
        ></ArticleDetails>
        <div
          className={styles.teaser}
          dangerouslySetInnerHTML={{ __html: teaser }}
        ></div>
      </section>
    </article>
  );
}
