import * as React from "react"
import Homepage from "../layouts/homepage/homepage"
import {graphql} from "gatsby";
import {Link} from 'gatsby-plugin-react-i18next';
import ArticleImageTitleTeaser from "../components/cards/article-image-title-teaser/article-image-title-teaser";
import ArticleTitleTeaser from "../components/cards/article-title-teaser/article-title-teaser";
import HomepagePodcasts from "../components/cards/homepage-podcasts/homepage-podcasts";
import HomepageLibrarySection from "../components/cards/homepage-library-section/homepage-library-section";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Metatags from "../components/metatags/metatags";

export const data = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    latestTwoArticles: allNodeArticle(
      limit: 2
      filter: { langcode: { eq: $language } }
      sort: { fields: field_pub_date_mises_org, order: DESC }
    ) {
      edges {
        node {
          title
          langcode
          field_teaser {
            value
          }
          field_pub_date_mises_org(difference: "hours", fromNow: true)
          path {
            alias
          }
          relationships {
            field_images {
              relationships {
                field_media_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 640
                        height: 420
                        placeholder: BLURRED
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  }
                }
              }
            }
            field_article_type {
              name
            }
            field_author {
              title
              id
            }
          }
          id
        }
      }
    }
    latestTwoPodcasts: allNodeArticle(
      limit: 2
      filter: { langcode: { eq: $language } }
      sort: { fields: field_pub_date_mises_org, order: DESC }
    ) {
      edges {
        node {
          title
          langcode
          field_teaser {
            value
          }
          field_pub_date_mises_org(difference: "hours", fromNow: true)
          path {
            alias
          }
          relationships {
            field_images {
              relationships {
                field_media_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        height: 500
                        layout: CONSTRAINED
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  }
                }
              }
            }
            field_article_type {
              name
            }
            field_author {
              title
              id
            }
          }
          id
        }
      }
    }
    latestWireArticles: allNodeArticle(
      limit: 10
      filter: {
        langcode: { eq: $language }
        relationships: { field_article_type: { name: { eq: "Mises Wire" } } }
      }
    ) {
      edges {
        node {
          title
          langcode
          field_teaser {
            value
          }
          field_pub_date_mises_org(difference: "hours", fromNow: true)
          path {
            alias
          }
          relationships {
            field_article_type {
              name
            }
            field_austrian_school {
              id
              name
            }
            field_tags {
              id
              name
            }
            field_topics {
              id
              name
            }
            field_author {
              title
              id
            }
          }
          id
        }
      }
    }
    homepage: allNodeHomepage(filter: { langcode: { eq: $language } }) {
      edges {
        node {
          id
          field_giving_video_embed_url
          field_giving_link {
            title
            uri
          }
          metatag {
            attributes {
              name
              content
              href
              property
              rel
            }
            tag
          }
          relationships {
            field_homepage_library_link {
              field_link {
                uri
                title
              }
              relationships {
                field_image {
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 350
                            height: 270
                            placeholder: BLURRED
                            transformOptions: { cropFocus: CENTER, fit: COVER }
                          )
                        }
                      }
                    }
                  }
                  field_media_image {
                    alt
                  }
                }
              }
            }
            field_homepage_heros {
              field_hero_link {
                uri
                title
              }
              relationships {
                field_hero_image {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          title
          status
          field_homepage_giving_title
          field_homepage_giving_body {
            processed
          }
          field_homepage_tagline {
            processed
          }
          field_homepage_tagline_size
        }
      }
    }
  }
`;

export default function IndexPage({data, pageContext}) {
  const latestTwoArticles = data.latestTwoArticles.edges.map((edge,index) => {
    const image = getImage(edge.node.relationships.field_images[0].relationships.field_media_image.localFile.childImageSharp.gatsbyImageData)
    return <ArticleImageTitleTeaser
        key={index}
        title={edge.node.title}
        teaser={edge.node.field_teaser.value}
        articleUrl={edge.node.path.alias}
        authorName={edge.node.relationships.field_author[0].title}
        authorUrl={edge.node.articleUrl}
        imageObj={image}
        date={edge.node.field_pub_date_mises_org}
        articleType={edge.node.relationships.field_article_type.name}>
    </ArticleImageTitleTeaser>
  })
  const latestWireArticles = data.latestWireArticles.edges.map((edge,index) => {
    return (
      <ArticleTitleTeaser
        key={index}
        title={edge.node.title}
        tags={[
          ...edge.node.relationships.field_austrian_school,
          ...edge.node.relationships.field_tags,
        ]}
        teaser={edge.node.field_teaser.value}
        articleUrl={edge.node.path.alias}
        authorName={edge.node.relationships.field_author[0].title}
        authorUrl={edge.node.articleUrl}
        date={edge.node.field_pub_date_mises_org}
        articleType={edge.node.relationships.field_article_type.name}
      ></ArticleTitleTeaser>
    );
  })

  const latestTwoPodcasts = data.latestTwoPodcasts.edges.map((edge,index) => {
    const image = getImage(edge.node.relationships.field_images[0].relationships.field_media_image.localFile.childImageSharp.gatsbyImageData)
    return <HomepagePodcasts
        key={index}
        title={edge.node.title}
        teaser={edge.node.field_teaser.value}
        articleUrl={edge.node.path.alias}
        authorName={edge.node.relationships.field_author[0].title}
        authorUrl={edge.node.articleUrl}
        imageObj={image}
        date={edge.node.field_pub_date_mises_org}
        articleType={edge.node.relationships.field_article_type.name}>
    </HomepagePodcasts>
  })
  const heroSlides = data.homepage.edges[0].node.relationships.field_homepage_heros.map((hero,index) => {
    const image = getImage(hero.relationships.field_hero_image.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData)
    return <div key={index}>
      <Link to={hero.field_hero_link.uri}>
        <GatsbyImage image={image} alt={hero.relationships.field_hero_image.field_media_image.alt} />
      </Link>
    </div>

  });

  const homepageData = data.homepage.edges[0].node;
  const librarySections = homepageData.relationships.field_homepage_library_link.map((libSection,index) => {
    const image = getImage(libSection.relationships.field_image.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData)
    return <HomepageLibrarySection
        key={index}
        title={libSection.field_link.title}
        imageObj={image}
        link={libSection.field_link.uri}
        imageAlt={libSection.relationships.field_image.field_media_image.alt}
    ></HomepageLibrarySection>
  });

  return (
      <Homepage
          latestArticles={latestTwoArticles}
          latestWireArticles={latestWireArticles}
          latestPodcasts={latestTwoPodcasts}
          heroSlides={heroSlides}
          library={librarySections}
          homepageData={homepageData}
          >
        <Metatags metatagData={homepageData.metatag}></Metatags>
      </Homepage>
  );
}
